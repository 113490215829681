import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find, remove, cloneDeep } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  getCalendarOptions,
  moment,
  getDefaultTimezone,
  getHoursOfTheDay,
  getThisWeek,
} from 'utils/momentUtils';
import { events, pushEvent } from 'utils/GTM';
import { resetTimeSlots } from 'redux/reducers/timeSlotsReducer';
import { CalendarHeader } from './CalendarHeader';
import { CalendarBody } from './CalendarBody';
import Error from '../../views/error/pages/Error';

function BigCalendar(props) {
  const {
    calendarDetail,
    handleChange,
    useAvailableTimeSlots,
    duration,
    isMultiTime,
    intl,
  } = props;

  const slotDuration = isMultiTime
    ? 30
    : Math.min(
        duration,
        calendarDetail.slotDuration || Number.MAX_SAFE_INTEGER,
      );
  const selectedSlots = useSelector(state => state.selectedSlots.slots);
  const multiguestCalendarDetail = useSelector(
    state => state.multiguestEvent.bookingCalendarMultiguestScheduledEvent,
  );

  const prevSelectedHour = useSelector(
    state =>
      state.form.BookingDate &&
      state.form.BookingDate.values &&
      state.form.BookingDate.values.selectedHours,
  );
  const { year, month, date } = prevSelectedHour || {
    year: undefined,
    month: undefined,
    date: undefined,
  };

  let newDate;

  if (year) {
    newDate = moment(`${year}-${month}-${date}`, 'YYYY-MM-DD');
  } else {
    newDate = moment();
  }

  const [selectedTimezone, setSelectedTimezone] = useState(getDefaultTimezone);
  const [options, setOptions] = useState(
    getCalendarOptions({
      ...props,
      currentDate: newDate,
      selectedTimezone,
    }),
  );
  const dispatch = useDispatch();

  // NOTE: Not recommended to outlook companies with calendar events lasting longer than a day.
  const isHeavyLoadCompany = [
    5225, 5732, 6772, 5782, 8085, 4772, 7274, 9159, 10322, 11189, 5429, 6193,
    8709, 7152, 2087, 1241, 6244, 9, 10206, 7535,
  ].includes(calendarDetail.companyId);

  const queryString = new URLSearchParams(window.location.search);
  const debugMode = queryString.get('debug') || false;

  const {
    availableTimeSlots: timeSlots,
    startOfWeek,
    actions,
  } = useAvailableTimeSlots(
    calendarDetail.uid,
    duration,
    selectedTimezone,
    intl,
    isHeavyLoadCompany,
    debugMode,
  );

  const fetchWeek = (minTime, maxTime) => {
    actions.fetchAvailableTimeSlots(minTime, maxTime, timeSlots.firstTime);
  };

  function resetStatus() {
    dispatch(resetTimeSlots());
  }

  // used for timezones that are -hh:mm
  function handleNegativeTimezone(timezone = getDefaultTimezone) {
    const negativeTimezone = moment().tz(timezone).format('Z').startsWith('-');

    moment.updateLocale('en', {
      week: {
        dow: negativeTimezone ? 0 : 1,
      },
    });
    return negativeTimezone;
  }

  const getSelectedTimezone = timezone => {
    if (selectedTimezone !== timezone) {
      resetStatus();
    }
    setSelectedTimezone(timezone);
  };

  const setDateFromSmallCalendar = dateToSet => {
    if (
      !options.currentWeek.week[0].range('week').contains(moment(dateToSet))
    ) {
      resetStatus();
    }
    const momentNewDate = moment(dateToSet);
    setOptions({
      ...options,
      lastWeek: options.currentWeek.week[0],
      currentWeek: options.getThisWeek(momentNewDate),
    });
  };

  const getNextWeek = () => {
    resetStatus();
    pushEvent({ ...events.changeWeek('next') });
    setOptions({
      ...options,
      lastWeek: options.currentWeek.week[0],
      currentWeek: options.getNextWeek(
        options.currentWeek.selectedDate,
        handleNegativeTimezone(selectedTimezone),
      ),
    });
  };

  const getPrevWeek = () => {
    resetStatus();
    pushEvent({ ...events.changeWeek('previous') });
    setOptions({
      ...options,
      lastWeek: options.currentWeek.week[0],
      currentWeek: options.getPreviousWeek(
        options.currentWeek.selectedDate,
        handleNegativeTimezone(selectedTimezone),
      ),
    });
  };

  const onChange = (e, changedDate, currentTime, id) => {
    e.preventDefault();

    const selected = {
      id,
      year: changedDate.year(),
      month: changedDate.format('M'),
      date: changedDate.date(),
      day: changedDate.day(),
      time: currentTime,
      nextHour: moment(currentTime, 'HH:mm')
        .add(duration, 'minutes')
        .format('HH:mm'),
      duration,
    };
    if (isMultiTime) {
      if (find(selectedSlots, { id })) {
        const tempSelectedSlots = cloneDeep(selectedSlots);
        remove(tempSelectedSlots, { id });
        dispatch({
          type: 'selectedSlots_update',
          payload: { slots: tempSelectedSlots },
        });
      } else {
        dispatch({
          type: 'selectedSlots_update',
          payload: { slots: [...selectedSlots, selected] },
        });
      }
    } else {
      handleChange(selected);
    }
  };

  const onChangeMultiTime = () => {
    handleChange(selectedSlots, isMultiTime);
  };
  useEffect(() => {
    if (calendarDetail.bookingRangeMethod === 'absolute' && !isMultiTime) {
      const startDate = moment(calendarDetail.rangeStartDate);
      setOptions({
        ...options,
        selectedTimezone,
        getHoursOfTheDay: getHoursOfTheDay(
          options.businessOpeningTime,
          options.businessClosingTime,
          slotDuration,
          selectedTimezone,
        ),
        lastWeek: options.currentWeek.week[0],
        currentWeek: options.getThisWeek(
          startDate,
          handleNegativeTimezone(selectedTimezone),
        ),
        timeZoneChanged: true,
      });
    } else {
      setOptions({
        ...options,
        selectedTimezone,
        getHoursOfTheDay: getHoursOfTheDay(
          options.businessOpeningTime,
          options.businessClosingTime,
          slotDuration,
          selectedTimezone,
        ),
        lastWeek: options.currentWeek.week[0],
        currentWeek: options.getThisWeek(
          options.currentDate,
          handleNegativeTimezone(selectedTimezone),
        ),
        timeZoneChanged: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDetail, selectedTimezone]);

  useEffect(() => {
    if (
      !options.currentWeek.week[0].isSame(options.lastWeek, 'day') ||
      isMultiTime ||
      options.timeZoneChanged
    ) {
      fetchWeek(
        options.currentWeek.week[0].toISOString(true),
        options.currentWeek.week[
          options.currentWeek.week.length - 1
        ].toISOString(true),
      );

      if (options.timeZoneChanged) {
        const dupOptions = cloneDeep(options);
        dupOptions.timeZoneChanged = false;
        setOptions(dupOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.currentWeek]);

  useEffect(() => {
    if (isMultiTime && multiguestCalendarDetail) {
      const multiguestWeek = getThisWeek(
        moment(multiguestCalendarDetail?.schedulableFrom, 'YYYY-MM-DD'),
      );
      if (newDate.isBefore(multiguestWeek.selectedDate)) {
        setOptions({
          ...options,
          lastWeek: multiguestWeek.week[0],
          currentWeek: multiguestWeek,
          getHoursOfTheDay: getHoursOfTheDay(
            options.businessOpeningTime,
            options.businessClosingTime,
            30,
            selectedTimezone,
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiguestCalendarDetail]);

  useEffect(() => {
    if (startOfWeek != null && !isMultiTime) {
      const newWeek = options.getThisWeek(startOfWeek);
      setOptions({
        ...options,
        lastWeek: newWeek.week[0],
        currentWeek: newWeek,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfWeek]);

  if (timeSlots?.hasError) {
    return <Error message={timeSlots.error.message} />;
  }

  return (
    <CalendarHeader
      options={options}
      getPrevWeek={getPrevWeek}
      getNextWeek={getNextWeek}
      isTimeSlotLoading={!timeSlots.isSuccess}
      timeSlotsHasError={timeSlots.hasError}
      isMultiTime={isMultiTime}
      multiguestCalendarDetail={multiguestCalendarDetail}
      setDateFromSmallCalendar={setDateFromSmallCalendar}
      getSelectedTimezone={getSelectedTimezone}
    >
      <CalendarBody
        options={options}
        isTimeSlotLoading={!timeSlots.isSuccess}
        timeSlotsHasError={timeSlots.hasError}
        timeSlotsError={timeSlots?.error?.message}
        timeSlots={timeSlots}
        isMultiTime={isMultiTime}
        multiguestCalendarDetail={multiguestCalendarDetail}
        onChangeMultiTime={onChangeMultiTime}
        duration={duration}
        onChange={onChange}
        timezone={selectedTimezone}
        businessWeekDays={calendarDetail.businessWeekDays}
        isDebug={debugMode}
      />
    </CalendarHeader>
  );
}

BigCalendar.defaultProps = {
  calendarDetail: {},
  duration: 15,
  isMultiTime: false,
};

BigCalendar.propTypes = {
  calendarDetail: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  duration: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  useAvailableTimeSlots: PropTypes.func.isRequired,
  isMultiTime: PropTypes.bool,
  intl: PropTypes.func.isRequired,
};

export default injectIntl(BigCalendar);
